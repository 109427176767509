/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>📅 Data:</strong> Dissabte 10 de maig
                </p>
                <p>
                    <strong>⏰ Horari:</strong> 8.30 - 13.30h
                </p>
                <p>
                    <strong>💻 Modalitat:</strong> Online en directe per Zoom (amb gravació
                    disponible)
                </p>
                <p>
                    <strong>💰 Preu:</strong> 39,99 €
                </p>

                <p>
                    <strong>🎯 Objectiu</strong>
                </p>
                <p>
                    Aquest curs està dissenyat per preparar als aspirants a la{" "}
                    <strong>Policia Municipal de Sabadell</strong> en la resolució de{" "}
                    <strong>casos pràctics oficials</strong>. S’hi treballa la legislació aplicable,
                    l’anàlisi de conceptes clau i s’ofereix una <strong>metodologia eficaç</strong>{" "}
                    per afrontar amb garanties aquesta prova.
                </p>

                <p>
                    <strong>📚 Contingut</strong>
                </p>
                <ul>
                    <li>✔ Introducció i estructura de l’examen</li>
                    <li>
                        ✔ Normativa aplicable (Codi Penal, Llei de Seguretat Ciutadana i normativa
                        local)
                    </li>
                    <li>✔ Resolució de casos reals amb estratègies pràctiques</li>
                    <li>✔ Simulació d’examen amb correcció i feedback</li>
                </ul>

                <p>
                    <strong>🎓 Beneficis</strong>
                </p>
                <ul>
                    <li>✅ Casos pràctics actualitzats segons els criteris oficials</li>
                    <li>✅ Explicacions clares i detallades de la normativa</li>
                    <li>✅ Metodologia contrastada per superar amb èxit la prova</li>
                    <li>✅ Classes en directe amb interacció i accés a la gravació posterior</li>
                </ul>

                <p>
                    <strong>📍 Dirigit a:</strong> Aspirants a la Policia Municipal de Sabadell que
                    vulguin <strong>millorar en la resolució de casos</strong> i{" "}
                    <strong>reforçar coneixements legals</strong>.
                </p>

                <p>
                    <strong>📩 No deixis escapar aquesta oportunitat!</strong> Practica amb casos
                    reals, aprèn de professionals i{" "}
                    <strong>augmenta les teves opcions d'èxit</strong> a la prova oficial. 🚔
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="🔥 Domina la resolució de casos pràctics i apropa't a la teva plaça a la Policia Municipal de Sabadell!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
